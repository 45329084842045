export const state = () => ({
    connect_count: 0,
    loading: false,
    errors: {},
    error_message: ''
});

export const getters = {
    loading: (state) => !!state.connect_count,
    errors: (state) => state.errors,
    error_message: (state) => state.error_message
};

export const mutations = {
    loading_inc: state => {
        state.connect_count++;
    },
    loading_dec: state => {
        state.connect_count--;
    },
    clear_errors: (state, field) => {
        if (field) {
            if (state.errors?.[field]) state.errors[field] = null;
        } else {
            state.errors = {};
        }
    },
    clear_error_message: state => {
        state.error_message = '';
    },
    set_errors: (state, errors) => {
        state.errors = errors || {};
    },
    set_error_message: (state, error_message) => {
        state.error_message = error_message || '';
    }
};
