import Vue from 'vue';

import {
  ToastPlugin,
  BButton,
  BSpinner
} from 'bootstrap-vue';

Vue.use(ToastPlugin);

Vue.component('BButton', BButton);
Vue.component('BSpinner', BSpinner);
