import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex';
import hmacSHA256 from 'crypto-js/hmac-sha256'

if (!Vue.__axios_loading_errors__) {
    Vue.__axios_loading_errors__ = true;

    Vue.mixin({
        computed: {
            ...mapGetters({
                loading: 'axios/loading',
                errors: 'axios/errors',
                error_message: 'axios/error_message',
            })
        },
        watch: {
            '$store.state.axios.error_message': function (new_val) {
                if (!new_val) return;
                this.$store.commit('axios/set_error_message', '');
                this.$bvToast.toast(new_val, {
                    title: 'Error',
                    variant: 'danger',
                    toaster: 'b-toaster-top-center'
                });
            }
        },
        methods: {
            ...mapMutations({
                clear_errors: 'axios/clear_errors'
            })
        }
    });
}

export default function ({ $config, $axios, store }) {
    $axios.setBaseURL($config.api_url);

    $axios.onRequest(config => {
        if (!config?.silent) {
            store.commit('axios/loading_inc');
            store.commit('axios/clear_errors');
            store.commit('axios/clear_error_message');
        }

        if (!config.external) {
            config.headers['BS-Signature'] = hmacSHA256(
                JSON.stringify(config.data),
                $config.cur_site_config.domain_name.api_key
            ).toString();
        }
        return config;
    });

    $axios.onError(error => {
        if (!error?.config?.silent) {
            try {
                store.commit('axios/loading_dec');
                store.commit('axios/set_errors', error?.response?.data?.errors);

                if (!(error?.response?.status === 422)) {
                    let msg = error?.response?.data?.message || error?.response?.data?.error || error.toString();
                    store.commit('axios/set_error_message', msg);
                }
            } catch (e) {
                console.error(e);
            }
        }
        //return true; //for disable error
    });

    $axios.onResponse(response => {
        if (!response?.config?.silent) {
            store.commit('axios/loading_dec');
        }
    });
}
