import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _22139184 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _1b596bfe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _500e56e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _28e626b3 = () => interopDefault(import('../pages/thanks.vue' /* webpackChunkName: "pages/thanks" */))
const _59e567af = () => interopDefault(import('../pages/address/edit.vue' /* webpackChunkName: "pages/address/edit" */))
const _03eee35e = () => interopDefault(import('../pages/address/verify.vue' /* webpackChunkName: "pages/address/verify" */))
const _cec8b2d6 = () => interopDefault(import('../pages/offer/details.vue' /* webpackChunkName: "pages/offer/details" */))
const _3be463d1 = () => interopDefault(import('../pages/property-details/appliances.vue' /* webpackChunkName: "pages/property-details/appliances" */))
const _c32e3c6c = () => interopDefault(import('../pages/property-details/bathrooms.vue' /* webpackChunkName: "pages/property-details/bathrooms" */))
const _29152650 = () => interopDefault(import('../pages/property-details/beds-baths.vue' /* webpackChunkName: "pages/property-details/beds-baths" */))
const _47fe1ddf = () => interopDefault(import('../pages/property-details/countertops.vue' /* webpackChunkName: "pages/property-details/countertops" */))
const _44ed5a2e = () => interopDefault(import('../pages/property-details/exterior.vue' /* webpackChunkName: "pages/property-details/exterior" */))
const _dfc7c33a = () => interopDefault(import('../pages/property-details/images.vue' /* webpackChunkName: "pages/property-details/images" */))
const _9062456e = () => interopDefault(import('../pages/property-details/kitchen.vue' /* webpackChunkName: "pages/property-details/kitchen" */))
const _3d7b1ee5 = () => interopDefault(import('../pages/property-details/yard.vue' /* webpackChunkName: "pages/property-details/yard" */))
const _0f3c057a = () => interopDefault(import('../pages/selling-details/message.vue' /* webpackChunkName: "pages/selling-details/message" */))
const _4bf8d898 = () => interopDefault(import('../pages/selling-details/priority.vue' /* webpackChunkName: "pages/selling-details/priority" */))
const _25af0e7c = () => interopDefault(import('../pages/selling-details/timeframe.vue' /* webpackChunkName: "pages/selling-details/timeframe" */))
const _62c5f030 = () => interopDefault(import('../pages/user/details.vue' /* webpackChunkName: "pages/user/details" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _22139184,
    name: "404"
  }, {
    path: "/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy"
  }, {
    path: "/terms",
    component: _500e56e6,
    name: "terms"
  }, {
    path: "/thanks",
    component: _28e626b3,
    name: "thanks"
  }, {
    path: "/address/edit",
    component: _59e567af,
    name: "address-edit"
  }, {
    path: "/address/verify",
    component: _03eee35e,
    name: "address-verify"
  }, {
    path: "/offer/details",
    component: _cec8b2d6,
    name: "offer-details"
  }, {
    path: "/property-details/appliances",
    component: _3be463d1,
    name: "property-details-appliances"
  }, {
    path: "/property-details/bathrooms",
    component: _c32e3c6c,
    name: "property-details-bathrooms"
  }, {
    path: "/property-details/beds-baths",
    component: _29152650,
    name: "property-details-beds-baths"
  }, {
    path: "/property-details/countertops",
    component: _47fe1ddf,
    name: "property-details-countertops"
  }, {
    path: "/property-details/exterior",
    component: _44ed5a2e,
    name: "property-details-exterior"
  }, {
    path: "/property-details/images",
    component: _dfc7c33a,
    name: "property-details-images"
  }, {
    path: "/property-details/kitchen",
    component: _9062456e,
    name: "property-details-kitchen"
  }, {
    path: "/property-details/yard",
    component: _3d7b1ee5,
    name: "property-details-yard"
  }, {
    path: "/selling-details/message",
    component: _0f3c057a,
    name: "selling-details-message"
  }, {
    path: "/selling-details/priority",
    component: _4bf8d898,
    name: "selling-details-priority"
  }, {
    path: "/selling-details/timeframe",
    component: _25af0e7c,
    name: "selling-details-timeframe"
  }, {
    path: "/user/details",
    component: _62c5f030,
    name: "user-details"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
