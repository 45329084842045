
    export default {
        data() {
            return {
                resources: {
                    gmaps: null,
                    gtm: null,
                    grecaptcha: null
                },
            	customCss: this.$config.cur_site_config.custom_css,

                startEvents: ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'],

                cssProps: {
                    '--active-color': this.$config.cur_site_config.active_color,
                    '--active-color-lighten-10': this.$helper.color_lighten(this.$config.cur_site_config.active_color, 10),
                    '--active-color-darken-20': this.$helper.color_lighten(this.$config.cur_site_config.active_color, -20),
                }
            }
        },
        mounted() {
            this.$nuxt.$once('googleResources:load', this.startGoogleResources);
            this.$nuxt.$once('app:ready', this.startGtm);

            this.startEvents.forEach(name => {
                document.addEventListener(name, this.initApp, true);
            });

            window.addEventListener("load", event => {
                setTimeout(() => {
                    this.initApp();
                }, 5000)
            });
        },
        methods: {
            startGoogleResources() {
                if (!this.resources.gmaps) {
                    this.resources.gmaps = true;
                    window.initGoogleMapApi = () => this.$nuxt.$emit('googleMapApi:init');

                    let gmapiScript = document.createElement('script');
                    gmapiScript.setAttribute('src',
                        `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${this.$config.cur_site_config.integrations.google_api_key}&libraries=places&language=en&callback=initGoogleMapApi&loading=async`
                    );
                    document.head.appendChild(gmapiScript);
                }
                if (!this.resources.grecaptcha) {
                    this.resources.grecaptcha = true;
                    const grecaptchaScript = document.createElement('script');
                    grecaptchaScript.setAttribute('src',
                        `https://www.google.com/recaptcha/enterprise.js?render=${this.$config.google_recaptcha_key}`
                    );
                    document.head.appendChild(grecaptchaScript);
                }
            },
            startGtm() {
                if (!this.resources.gtm && this.$config.cur_site_config.google_tag_manager_key) {
                    this.resources.gtm = true;
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.defer=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', this.$config.cur_site_config.google_tag_manager_key);

                    document.body.insertAdjacentHTML(
                        'afterbegin',
                        `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${this.$config.cur_site_config.google_tag_manager_key}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
                    );
                }
            },
            initApp() {
                this.$nuxt.$emit('app:ready');
                this.startEvents.forEach(name => {
                    document.removeEventListener(name, this.initApp, true);
                });
            }
        }
    }
