import Vue from 'vue'

Vue.filter('shortprice', (value) => {
    let n = Math.round(Number(value) / 1000);
    return '$' + n.toFixed(0).replace(/./g, (c, i, a) => {
        return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
    }) + 'k';
});

Vue.filter('fullprice', (value) => {
    let n = Number(value);
    return '$' + n.toFixed(0).replace(/./g, (c, i, a) => {
        return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
    });
});

Vue.filter('phoneNumberLocal', value => {
    const cleaned = ('' + value)
        .replace('+1', '')
        .replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return value;
})
