import _ from 'lodash'
import $settings from '../plugins/settings';

export const state = () => ({
    signed_route_for_update: null,
    signed_route_for_load_images: null,
    street_address: null,
    city: null,
    google_county: null,
    state: null,
    zip: null,
    unit: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    latitude: null,
    longitude: null,
    bedrooms: null,
    bathrooms: null,
    half_bathrooms: null,
    year_built: null,
    sqft: null,
    countertops: null,
    appliances: null,
    kitchen: null,
    bathrooms_condition: null,
    yard: null,
    exterior: null,
    priority: null,
    timeframe: null,
    message: null,
    zestimate: null,
    price_offered_from: null,
    price_offered_to: null,
    asking_price: null,
    feel: null,
    source: 'PPC',
    gclid: null,
    msclkid: null,
    images: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
    utm_content: null,
    google_geocode: null,
    opt_in: false
});

const getCrateData = (state) => {
    const createFields = [
        'street_address',
        'city',
        'state',
        'zip',
        'google_county',
        'latitude',
        'longitude',
        'google_geocode',

        'source',
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'gclid',
        'msclkid',
    ];
    return pickFields(state, createFields);
};

const getUpdateData = (state) => {
    const updateFields = [
        'street_address',
        'city',
        'state',
        'zip',
        'google_county',
        'latitude',
        'longitude',
        'google_geocode',
        'unit',
        'first_name',
        'last_name',
        'email',
        'phone',
        'bedrooms',
        'bathrooms',
        'half_bathrooms',
        'year_built',
        'sqft',
        'countertops',
        'appliances',
        'kitchen',
        'bathrooms_condition',
        'yard',
        'exterior',
        'priority',
        'timeframe',
        'message',
        'zestimate',
        'price_offered_from',
        'price_offered_to',
        'asking_price',
        'feel',
        'opt_in'
    ];
    return pickFields(state, updateFields);
};

const pickFields = (state, fields) => {
    return fields.reduce((acc, field) => {
        if (state[field] !== undefined && state[field] !== null) {
            acc[field] = state[field];
        }
        return acc;
    }, {});
};

export const getters = {
    fullAddress: state => `${state.street_address}, ${state.city}, ${state.state}, ${state.zip}`,
};

export const mutations = {
    update: (state, data) => {
        _.each(data, (value, key) => {
            if(!state.hasOwnProperty(key)) return;
            state[key] = value;
        });
    },
    clear: state_p => {
        let clear_state = state();
        for (let key in clear_state) {
            state_p[key] = clear_state[key];
        }
    }
};

export const actions = {
    async post({commit, state}) {
        let {signed_route_for_update, signed_route_for_load_images} = await this.$axios.$post(
            $settings.API.lead,
            {
                ...getCrateData(state),
                recaptcha_token: window.grecaptcha && await grecaptcha.enterprise.execute(
                    window.$nuxt.$config?.google_recaptcha_key,
                    {action: 'SELLER_DRAFT_LEAD'}
                ) || null
            }
        );
        commit('update', {signed_route_for_update, signed_route_for_load_images});
    },

    async put({commit, state}) {
        return await this.$axios.$put(state.signed_route_for_update, getUpdateData(state));
    },

    async uploadImages({commit, state}) {
        if(!state.images) return true;
        let formData = new FormData();
        state.images.forEach((image, key) => {
            formData.append(`file${key}`, image.file);
        });

        let res = await this.$axios.$put(state.signed_route_for_load_images, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
        commit('update', {images: res.images});
    }
};
